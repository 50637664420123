<template>
	<b-card>
		<b-form-group
			:label="`${$t('Device Name')}：`"
		>
			test
		</b-form-group>
		<b-form-group :label="`${$t('Product Description')}：`">
			<quill-editor
				id="quil-content"
				height="900"
				v-model="formData.message"
				:options="editorOption"
				@change="onEditorChange($event)"
			/>
		</b-form-group>
		<div class="d-flex mt-2">
			<b-button
				variant="primary"
				class="mr-2"
				type="button"
			>
				{{$t('Save')}}
			</b-button>
			<b-button
				type="button"
				variant="outline-secondary"
				@click="back"
			>
				{{$t('Cancel')}}
				
			</b-button>
		</div>
	</b-card>
</template>

<script>
import {mapState} from 'vuex'
import {
	BCard, BForm, BFormGroup, BFormInput, BFormFile, BFormInvalidFeedback, BButton,BFormDatepicker, BFormTimepicker, BFormCheckboxGroup,BRow,BCol
} from 'bootstrap-vue'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import { quillEditor } from 'vue-quill-editor'

export default {
	components: {
		BCard,
		BForm,
		BFormGroup,
		BFormInput,
		BFormFile,
		BFormInvalidFeedback,
		BButton,
		BFormDatepicker,
		BFormTimepicker,
		BFormCheckboxGroup,
		BRow,BCol,

		quillEditor
	},
	computed: {
		...mapState({
			locale: state=>state.app.locale
		}),
	},
	data() {
		return {
			formData:  {
				content: ''
			},  
			editorOption: {
				modules:{
					toolbar:[
						['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
						[{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
						[{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
						[{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
						[{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
						[{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
						[{ 'font': [] }],     //字体
						[{ 'align': [] }],    //对齐方式
						['clean'],    //清除字体样式
						['image']    //上传图片、上传视频
					]
				},
				placeholder: this.$t('Please Enter...'),
				height:800
			},
			resetForm () {}
		}
	},
	created () {
	},
	methods: {
		onEditorChange (e) {
			console.log(e)
		},
		onSubmit() {
			this.$store.dispatch('app-user/addUser', formData.value)
				.then(() => {
					emit('refetch-data')
					emit('update:is-active', false)
				})
		},
		back () {
			this.$router.back()
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
.quill-editor {
	height: 500px;
	margin-bottom: 60px;
}
</style>